import React from "react";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetContent,
  WidgetHeader,
  WidgetDetails,
  DataAvaliableWrapper,
  EditWrapper,
} from "./Widget.styled";
import { Icon } from "../../../../../elements";

const Widget = ({ title, height, maxheight, padding, maxwidth, children, actions }) => {
  return (
    <WidgetContainer height={height} maxheight={maxheight} maxwidth={maxwidth} padding={padding}>
      <WidgetHeader>
        <WidgetTitle>{title}</WidgetTitle>
        <WidgetDetails>
          {actions && actions}
        </WidgetDetails>
      </WidgetHeader>
      <WidgetContent>
        {children ? (
          children
        ) : (
          <DataAvaliableWrapper id="dataAvaliableWrapper">
            <p>No data available</p>
            <p>
              No data is available for this card at the moment. Please check
              again later.
            </p>
            <p>
              <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
            </p>
          </DataAvaliableWrapper>
        )}
      </WidgetContent>
    </WidgetContainer>
  );
};

export default Widget;
