import React, { useEffect, useState, useMemo } from "react";
import Widget from "./Widget";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import {
  InfoList,
  SubTitle,
  NestedSlider,
  PanelLarge,
  ImageContainer,
  VehicleImage,
} from "./Widget.styled";

import { SliderPrincipalPanel } from "../../../../../elements/v2/DynamicCarousel/DynamicCarousel.styled";
import { Icon } from "../../../../../elements";
import Spinner from "../../../../../components/common/Spinner";
import CheckIcon from "./CheckIcon";
import FormattedDate from "./FormattedDate";
import DynamicCarousel from "../../../../../elements/v2/DynamicCarousel/DynamicCarousel";
import NoDataAvaliable from "./NoDataAvaliable";

const formatMileage = (mileage) => {
  return mileage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const GarageCard = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const [garageInfo, setGarageInfo] = useState([]);
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(0);

  useEffect(() => {
    setGarageInfo(unifiedCustomerProfileStore.garageInfo);
  }, [unifiedCustomerProfileStore.garageInfo]);

  useEffect(() => {
    if (garageInfo.length > 0) {
      const selectedVehicle = garageInfo[selectedVehicleIndex];
      if (selectedVehicle?.vin_no) {
        unifiedCustomerProfileStore.fetchVehicleVinPictures(
          selectedVehicle.vin_no,
        );
      }
    }
  }, [garageInfo, selectedVehicleIndex]);

  const garage = useMemo(
    () => unifiedCustomerProfileStore.garageInfo || [],
    [unifiedCustomerProfileStore.garageInfo],
  );

  const vehicleImages = useMemo(
    () => unifiedCustomerProfileStore.vehicleVinPictures || [],
    [unifiedCustomerProfileStore.vehicleVinPictures],
  );

  const handleMoveEnd = (e) => {
    setSelectedVehicleIndex(e.index);
  };

  const renderVehicleItem = (currentVehicle, idx) => (
    <SliderPrincipalPanel key={idx}>
      <NestedSlider>
        {unifiedCustomerProfileStore.isLoadingVehicleImages ? (
          <Spinner />
        ) : vehicleImages.length === 0 ? (
          <NoDataAvaliable text={"image"} />
        ) : (
          <DynamicCarousel
            items={vehicleImages}
            renderItem={(imageUrl, index) => (
              <PanelLarge key={index}>
                <ImageContainer>
                  <VehicleImage
                    src={imageUrl}
                    alt={`Vehicle view ${index + 1}`}
                  />
                </ImageContainer>
              </PanelLarge>
            )}
            navigationType="pagination"
            paginationWidth="35"
          />
        )}
      </NestedSlider>

      <InfoList isGarageCard={true}>
        <li>
          <SubTitle>Vehicle/Model: </SubTitle>
          <span>
            {currentVehicle.vehicle_make
              ? `${currentVehicle.vehicle_make}/${currentVehicle.vehicle_model}`
              : currentVehicle.vehicle_model}
          </span>
        </li>
        <li>
          <SubTitle>Year:</SubTitle>
          <span>{currentVehicle.vehicle_model_year}</span>
        </li>
        <li>
          <SubTitle>Model Description:</SubTitle>
          <span>{currentVehicle.model_description}</span>
        </li>
        <li>
          <SubTitle>Estimated mileage:</SubTitle>
          <span>{formatMileage(currentVehicle.estimated_milage)}</span>
        </li>
        <li>
          <SubTitle>Last record mileage:</SubTitle>
          <span>{formatMileage(currentVehicle.last_recorded_mileage)}</span>
        </li>
        <li>
          <SubTitle>Last service:</SubTitle>
          <FormattedDate date={currentVehicle.last_service} />
        </li>
        <li>
          <SubTitle>Purchase date:</SubTitle>
          <FormattedDate date={currentVehicle.purchase_date} />
        </li>
        <li>
          <SubTitle>Purchase used:</SubTitle>
          <CheckIcon value={currentVehicle.purchase_used} />
        </li>
        <li>
          <SubTitle>VIN:</SubTitle>
          <span>{currentVehicle.vin_no}</span>
        </li>
      </InfoList>
    </SliderPrincipalPanel>
  );

  return (
    <Widget
      title="Garage"
      height={garage.length === 0 ? "auto" : "500px"}
      maxwidth={"448px"}
    >
      {unifiedCustomerProfileStore.isLoading ? (
        <Spinner />
      ) : (
        <>
          {garage.length === 0 ? (
            <NoDataAvaliable />
          ) : (
            <DynamicCarousel
              items={garageInfo}
              renderItem={renderVehicleItem}
              navigationType="arrow"
              onMoveEnd={handleMoveEnd}
            />
          )}
        </>
      )}
    </Widget>
  );
});

export default GarageCard;
