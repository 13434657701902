import { action, makeObservable, observable, flow, toJS } from "mobx";
import dayjs from 'dayjs';
import { API } from "aws-amplify";
import { getEvent, putTrigger, getTrigger } from "../../graphql/queries";
import ConversationsActionsStore from "./ConversationsActionsStore";
import RulesStore from "./pages/RulesStore";

class ViewConversationStore {
    conversationsActionsStore = new ConversationsActionsStore()
    rulesPage = new RulesStore();

    isUpdating = false;
    isLoading = false;
    isError = false;
    id = "";
    account = "";
    version = "";
    editMode = "r";

    eventName = "";
    source = "";
    description = "";
    startDate = null;
    endDate = null;
    startTime = null;
    endTime = null;
    minEndTime = null;
    event_id = "";

    eventNameRead = "";
    sourceRead = "";
    descriptionRead = "";
    startDateRead = null;
    endDateRead = null;
    startTimeRead = null;
    endTimeRead = null;
    eventsCatalog = [];

    changesDetected = false;
    finishedInitialLoad = false;

    warningMessage = "";

    constructor() {
        makeObservable(this, {
            conversationsActionsStore: observable,
            rulesPage: observable,
            isUpdating: observable,
            isLoading: observable,
            isError: observable,
            id: observable,
            account: observable,
            eventName: observable,
            source: observable,
            description: observable,
            startDate: observable,
            endDate: observable,
            startTime: observable,
            endTime: observable,
            minEndTime: observable,
            event_id: observable,
            editMode: observable,

            eventNameRead: observable,
            sourceRead: observable,
            descriptionRead: observable,
            startDateRead: observable,
            endDateRead: observable,
            startTimeRead: observable,
            endTimeRead: observable,
            eventsCatalog: observable,
            finishedInitialLoad: observable,
            warningMessage: observable,
            
            updateChangesDetected: action,
            updateFormField: action,
            canCreate: action,
            clear: action,
            clearNewModalForm: action,
            onClearError: action,
            isReadMode: action,
            clearWarning: action,
            onCreate: flow,
            getEventsCatalog: flow,
            setId: flow,
            publish: action,
            draft: action,
            updateTrigger: flow,
            fetchTrigger: flow
        });
    }

    isReadMode(){
        return this.editMode.toLowerCase() === 'r';
    }
    
    update(){
        this.eventNameRead = this.eventName;
        this.sourceRead = this.source;
        this.descriptionRead = this.description;
        this.startDateRead = this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0);
        this.endDateRead = this.endDate && this.endTime ? this.endDate.set('hour', this.endTime?.get('hour')).set('minute', this.endTime.get('minute')) : "";
        this.changesDetected = true;
    }

    updateFormField(field, value) {
        this[field] = value;
    }

    canCreate() {
        return this.eventName && this.event_id;
    }

    clear() {
        this.id = "";
        this.account = "";
        this.version = "";
        this.eventNameRead = "";
        this.sourceRead = "";
        this.descriptionRead = "";
        this.startDateRead = null;
        this.endDateRead = null;
        this.startTimeRead = null;
        this.endTimeRead = null;
        this.eventsCatalog = [];
        this.changesDetected = false;
        this.finishedInitialLoad = false;
        this.editMode = 'r';
        this.conversationsActionsStore.clear()
        this.rulesPage.clear()
    }

    clearNewModalForm(){
        this.isUpdating = false;
        this.isLoading = false;
        this.isError = false;
        this.eventName = "";
        this.source = "";
        this.description = "";
        this.startDate = null;
        this.endDate = null;
        this.startTime = null;
        this.endTime = null;
        this.event_id = "";
    }

    *onCreate(account, callback) {
        try {
            this.isLoading = true;
            this.isError = false;
            const response = yield API.graphql({
                query: putTrigger,
                variables: { 
                    input: { 
                        account,
                        name: this.eventName,
                        description: this.description,
                        status: "draft",
                        origin: this.eventsCatalog.find(event => event.id === this.event_id)?.origin,
                        event_id: this.event_id,
                        rules: [],
                        actions: [],
                        publish: false,
                        start_at: "",
                        end_at: "",
                        id: ""
                    },
                },
                authMode :'AMAZON_COGNITO_USER_POOLS'
            });
            const createdTrigger = JSON.parse(response.data?.putTrigger?.body);
            callback(createdTrigger.id)
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *getEventsCatalog(){
        this.isLoading = true;
        this.isError = false;
        try {
            const response = yield API.graphql({
                query: getEvent,
                variables: { input: JSON.stringify({}) },
                authMode :'AMAZON_COGNITO_USER_POOLS'
            });
            const eventsCatalog = JSON.parse(response.data?.getEvent?.body);
            this.eventsCatalog = eventsCatalog
            this.rulesPage.setFields(eventsCatalog)
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *setId(id, account){
        this.id = id;
        this.account = account;
        yield this.fetchTrigger()
    }

    *fetchTrigger(){
        this.isLoading = true;
        this.isError = false;
        try {
            const response = yield API.graphql({
                query: getTrigger,
                variables: { input: { account: this.account, id: this.id } },
                authMode :'AMAZON_COGNITO_USER_POOLS'
            });

            const trigger = JSON.parse(response.data?.getTrigger?.body);
            const { name, description, start_at, end_at, event_id, version, actions, rules, status } = trigger;

            this.id = trigger.id;
            this.eventNameRead = this.eventName = name;
            this.sourceRead = this.source = this.event_id = event_id ;
            this.descriptionRead = this.description = description;
            this.startDateRead = this.startDate = this.startTime = start_at ? dayjs(start_at) : null;
            this.endDateRead = this.endDate = this.endTime = end_at ? dayjs(end_at) : null;
            this.version = version;
            this.finishedInitialLoad = true;
            this.editMode = status === 'draft' ? 'w' : 'r'
            this.conversationsActionsStore.loadExistingActions(actions)
            this.rulesPage.loadExistingRules(rules)
            
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    draft(){
        this.changesDetected = false;
        this.updateTrigger('draft')

    }

    publish(){
        if(!this.startDateRead){
            return this.warningMessage = "You must choose a start date before publishing."
        }
        this.changesDetected = false;
        this.updateTrigger('active')
    }

    clearWarning(){
        this.warningMessage = "";
    }

    updateChangesDetected(value){
        this.changesDetected = value;
    }

    *updateTrigger(status) {
        try {
            this.isUpdating = true;
            this.isError = false;
            const response = yield API.graphql({
                query: putTrigger,
                variables: { 
                    input: { 
                        account: this.account,
                        name: this.eventNameRead,
                        description: this.descriptionRead,
                        status,
                        origin: this.eventsCatalog.find(event => event.id === this.event_id)?.origin,
                        event_id: this.event_id,
                        rules: this.rulesPage.saveRules().map(rule => JSON.stringify(rule)),
                        actions: this.conversationsActionsStore.actions.map(action => JSON.stringify(action)),
                        publish: status !== 'draft',
                        start_at: this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0),
                        end_at: this.endDate && this.endTime ? this.endDate.set('hour', this.endTime?.get('hour')).set('minute', this.endTime.get('minute')) : "",
                        id: this.id,
                        version: this.version
                    },
                },
                authMode :'AMAZON_COGNITO_USER_POOLS'
            });

            yield this.fetchTrigger()
        } catch (error) {
            this.isError = true;
        } finally {
            this.isUpdating = false;
        }
    }

    onClearError() {
        this.isError = false;
    }
}

export default ViewConversationStore;
