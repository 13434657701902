import styled, { css } from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    ${(props) => props.$zIndex && css`
        z-index: 1000;
    `}

    ${(props) => props.isOpen && css`
        display: flex;
    `}
    
`;

const ModalWrapper = styled.div`
    position: relative;
    width: ${props => props.$width|| '440px'};
    height: ${props => props.$height || '300px'};
    background-color: white;
    border-radius: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 30px;
`;

const ModalTitle = styled.div`
    font-weight: bold;
    font-size: 28px;
`;

const ModalImage = styled.img`
    margin-bottom: 20px;
`;

const StyledModalSubtitle = styled.p`
    font-size: 20px;
    margin-bottom: 0;
`;

const StyledModalSubtitle2 = styled.p`
    font-size: 20px;
    margin-top: 0;
`;

const StyledModalSubtitle3 = styled.p`
    font-size: 40px;
    margin-top: 0;
`;

const ModalSubtitle = ({ children }) => <StyledModalSubtitle>{children}</StyledModalSubtitle>;
const ModalSubtitle2 = ({ children }) => <StyledModalSubtitle2>{children}</StyledModalSubtitle2>;
const ModalSubtitle3 = ({ children }) => <StyledModalSubtitle3>{children}</StyledModalSubtitle3>;

const ModalFooterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;

const ModalCloseButton = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const ModalTestAgentButton = styled.img`
    cursor: pointer;
`;

const DragAndDropAssistantWrapper = styled.div`
    margin-top: 40px;
`;

export {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalSubtitle,
    ModalSubtitle2,
    ModalSubtitle3,
    ModalImage,
    ModalFooterWrapper,
    ModalCloseButton,
    ModalTestAgentButton,
    DragAndDropAssistantWrapper
};
