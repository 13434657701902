import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import {
  getCampaigns,
  putCampaignMarketing
} from "../../graphql/queries";
import _ from "lodash";
import dayjs from "dayjs";
import {v4 as uuidv4} from 'uuid';

class MarketingStore {
	isLoading = false;
	isError = false;
	isSuccessful = false;
	emailTemplatePayload = [];
	emailTemplateBinding = [];
	selectedAgent = null;
	marketingConversations = [];
	marketingConversation = {};
	emailTemplateExportAction = null;
	name = "";
	description = "";
	startDate = null;
    endDate = null;
    startTime = null;
    endTime = null;
    minEndTime = null;
	nameRead = "";
	descriptionRead = "";
    startDateRead = null;
    endDateRead = null;
    startTimeRead = null;
    endTimeRead = null;
	activeCampaign = true;
	status = 'DRAFT';
	confirmationDialogMessage = null;
	contactFile = {};
	numberSegments = 0;
	contactCSVFile = null;

	constructor() {
		makeObservable(this, {
			isLoading: observable,
			isError: observable,
			isSuccessful: observable,
			selectedAgent: observable,
			marketingConversations: observable,
			marketingConversation: observable,
			emailTemplateExportAction: observable,
			name: observable,
			description: observable,
			nameRead: observable,
			descriptionRead: observable,
			startDateRead: observable,
            endDateRead: observable,
            startTimeRead: observable,
            endTimeRead: observable,
			startDate: observable,
            endDate: observable,
            startTime: observable,
            endTime: observable,
			activeCampaign: observable,
			status: observable,
			confirmationDialogMessage: observable,
			contactFile: observable,
			numberSegments: observable,
			contactCSVFile: observable,
			setMarketingAttribute: action,
			clear: action,
			onClearError: action,
			setEmailTemplateExportAction: action,
			setSelectedAgent: action,
			update: action,
			clearConfirmationDialog: action,
			getMarketingConversations: flow,
			newMarketingConversation: flow,
			save: flow,
			cancel: flow
		});
	}

	clearConfirmationDialog(){
		this.confirmationDialogMessage = null;
	}

	update(){
        this.nameRead = this.name;
        this.descriptionRead = this.description;
        this.startDateRead = this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0);
        this.endDateRead = this.endDate && this.endTime ? this.endDate.set('hour', this.endTime?.get('hour')).set('minute', this.endTime.get('minute')) : "";
    }

	*newMarketingConversation(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: { 
						customer, 
						name: "New Marketing Campaign",
						start_datetime: dayjs(),
						end_datetime: dayjs().add(1, 'day'),
						status: "DRAFT",
						metadata: JSON.stringify({
							description: "This is a marketing campaign"
						}),
						contact_file: JSON.stringify({}), 
						agent_id: ""
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const newConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			return newConversation.id

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	*getMarketingConversations(customer, id=""){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: getCampaigns,
				variables: { input: { customer, id} },
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const conversations = JSON.parse(response.data?.getCampaigns?.body);

			if(id){
				this.marketingConversation = conversations;
				this.selectedAgent = conversations.agent_id;
				this.name = this.nameRead = conversations.name;
				this.description = this.descriptionRead = conversations.metadata?.description;
				this.startDateRead = this.startDate = this.startTime = conversations.start_datetime ? dayjs(conversations.start_datetime) : null;
				this.endDateRead = this.endDate = this.endTime = conversations.end_datetime ? dayjs(conversations.end_datetime) : null;
				this.activeCampaign = conversations.status.toLowerCase() === 'active';
				this.contactFile = conversations.contact_file;
				this.status = conversations.status;
				this.contactCSVFile = conversations.metadata?.uploaded_contact_file;
			}else{
				this.marketingConversations = conversations
			}

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	updateBindingsAndPayload(payload = [], bindings = []) {
		this.emailTemplatePayload = _.cloneDeep(payload);
		this.emailTemplateBinding = _.cloneDeep(bindings);
	}

	setMarketingAttribute(attribute, value) {
		this[attribute] = value;
	}

	clear() {
		this.isLoading = false;
		this.isError = false;
		this.isSuccessful = false;
		this.emailTemplatePayload = [];
		this.emailTemplateBinding = [];
		this.selectedAgent = null;
		this.marketingConversations = [];
		this.marketingConversation = {};
		this.emailTemplateExportAction = null;
		this.name = "";
		this.description = "";
		this.startDate = null;
		this.endDate = null;
		this.startTime = null;
		this.endTime = null;
		this.minEndTime = null;
		this.nameRead = "";
		this.descriptionRead = "";
		this.startDateRead = null;
		this.endDateRead = null;
		this.startTimeRead = null;
		this.endTimeRead = null;
		this.activeCampaign = true;
		this.status = 'DRAFT';
		this.confirmationDialogMessage = null;
		this.contactFile = {};
		this.numberSegments = 0;
		this.contactCSVFile = null;
	}

	onClearError() {
		this.isError = false;
	}

	setSelectedAgent(id){
		this.selectedAgent = id;
	}

	setEmailTemplateExportAction(action){
		this.emailTemplateExportAction = action;
	}

	*save(customer, filters, segment_count){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: {
						name: this.nameRead,
						id: this.marketingConversation.id,
						customer, 
						start_datetime: this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0),
						end_datetime: this.endDate.set('hour', this.endTime?.get('hour') || 0).set('minute', this.endTime.get('minute') || 0),
						status: this.status,
						metadata: JSON.stringify({
							description: this.descriptionRead,
							segment_count: filters.length ? segment_count : (this.numberSegments || this.marketingConversation.metadata?.segment_count),
							filters: filters,
							actions: [{
								action: "send_email",
								id:  this.marketingConversation.metadata?.actions?.[0]?.id || uuidv4(),
								binding: this.emailTemplateBinding,
								payload: this.emailTemplatePayload
							}],
							uploaded_contact_file: filters.length ? null : this.contactCSVFile
						}),
						contact_file: filters.length ? JSON.stringify({}) : JSON.stringify(this.contactFile), 
						agent_id: this.selectedAgent
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);

			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status
			this.activeCampaign = this.status.toLowerCase() === 'active'

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}

	}

	*cancel(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: {
						id: this.marketingConversation.id,
						customer, 
						status: 'CANCELLED',
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status
			this.activeCampaign = this.status.toLowerCase() === 'active'

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}

	}

}

export default MarketingStore;
